document.addEventListener('DOMContentLoaded', () => {
    const header = document.querySelector('.site-header') as HTMLElement;
    const mobileMenuToggle = document.querySelector('.mobile-menu-toggle') as HTMLButtonElement;
    const mainNavigation = document.querySelector('.main-navigation') as HTMLElement;
    const adminBar = document.getElementById('wpadminbar');
    const body = document.body;

    // Add admin-bar class to body if admin bar is present
    if (adminBar) {
        body.classList.add('admin-bar');
    }

    // Function to update header position based on admin bar
    const updateHeaderPosition = () => {
        if (adminBar) {
            const adminBarHeight = adminBar.offsetHeight;
            header.style.top = `${adminBarHeight}px`;
        }
    };

    // Call once on load
    updateHeaderPosition();

    // Update on window resize
    window.addEventListener('resize', updateHeaderPosition);

    // Mobile menu toggle with scrolled class addition
    mobileMenuToggle?.addEventListener('click', () => {
        header?.classList.toggle('mobile-menu-active');
        // Add scrolled class when mobile menu is active
        if (header?.classList.contains('mobile-menu-active')) {
            header.classList.add('scrolled');
        } else {
            // Only remove scrolled class if we're not past the hero
            const heroElement = document.querySelector('.hero') as HTMLElement;
            if (heroElement && window.pageYOffset <= heroElement.offsetTop) {
                header.classList.remove('scrolled');
            }
        }
    });

    // Scroll effect
    let lastScrollTop = 0;

    window.addEventListener('scroll', () => {
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        const heroElement = document.querySelector('.hero') as HTMLElement;

        if (heroElement && window.pageYOffset > heroElement.offsetTop || header.classList.contains('mobile-menu-active')) {
            header.classList.add('scrolled');
        } else {
            header.classList.remove('scrolled');
        }

        if (scrollTop > lastScrollTop && scrollTop > header.offsetHeight) {
            // Don't hide header when mobile menu is active
            if (!header.classList.contains('mobile-menu-active')) {
                // Scrolling down
                header.style.transform = `translateY(-${header.offsetHeight}px)`;
            }
        } else {
            // Scrolling up
            header.style.transform = 'translateY(0)';
            updateHeaderPosition(); // Ensure correct positioning when scrolling up
        }
        lastScrollTop = scrollTop;
    });

    // Submenu toggle for mobile
    const menuItemsWithChildren = mainNavigation?.querySelectorAll('.menu-item-has-children');
    menuItemsWithChildren?.forEach((item) => {
        const link = item.querySelector('a') as HTMLAnchorElement;
        const submenuIndicator = link.querySelector('.submenu-indicator');

        // Create a new button for toggling the submenu
        const toggleButton = document.createElement('button');
        toggleButton.className = 'submenu-toggle';
        toggleButton.innerHTML = '&#9662;'; // Down arrow
        toggleButton.setAttribute('aria-label', 'Toggle submenu');

        // Only add the toggle button if there's a submenu indicator
        if (submenuIndicator) {
            // Replace the submenu indicator with the toggle button
            submenuIndicator.replaceWith(toggleButton);
        }

        // Handle toggle button click
        toggleButton.addEventListener('click', (e) => {
            e.preventDefault();
            e.stopPropagation();
            if (window.innerWidth <= 768) {
                item.classList.toggle('active');
            }
        });

        // Remove the click handler from the link itself
        link.addEventListener('click', (e) => {
            // Allow the link to work normally
            if (window.innerWidth <= 768) {
                // Only prevent default if the submenu isn't open yet
                if (!item.classList.contains('active') && item.querySelector('.sub-menu')) {
                    e.preventDefault();
                    item.classList.add('active');
                }
            }
        });
    });
});
